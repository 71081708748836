<template>
  <div>
    <el-dialog
      :visible.sync="dialogVisible"
      width="390px"
      style="text-align: center"
    >
      <p style="margin-top: 20px">扫码下载</p>
      <p style="line-height: 50px">智慧停车管理端APP</p>
      <vue-qr
        style="margin: 38px 0"
        :text="downloadData"
        :logoSrc="require('../../../assets/img/AIpark28.png')"
        :size="234"
        :margin="0"
      ></vue-qr>
    </el-dialog>
  </div>
</template>
<script>
import VueQr from 'vue-qr'
export default {
  components: {
    VueQr
  },
  data() {
    return {
      dialogVisible: false,
      downloadData: window.location.protocol + "//" + window.location.host + '/downloadboss',
    };
  },
  mounted() {  // 切记  一定要在组件里加
    this.$nextTick(() => {
      const body = document.querySelector("body");
      if (body.append) {
        body.append(this.$el);
      } else {
        body.appendChild(this.$el);
      }
    });
  },
  methods: {
    showDialogVisible() {
      this.dialogVisible = !this.dialogVisible
    }
  }
};
</script>
<style lang="stylus" scoped></style>
