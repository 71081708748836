<template>
  <div
    class="login"
    v-loading="ssoLoading"
    element-loading-text="加载中..."
    element-loading-background="#fff"
  >
    <div
      class="loginBg"
      :style="{
        background: 'white url(' + loginBg + ') center/cover no-repeat',
      }"
    >
      <!-- <iframe
        frameborder="0"
        scrolling="no"
        src="static/loginBg.html"
        width="100%"
        height="100%"
      ></iframe> -->
    </div>
    <div class="middle">
      <h1 class="header">
        <img :src="loginTitleImage" width="106" />
        <p>{{ loginTitleName }}</p>
      </h1>
      <div class="wrapper">
        <codeLogin @isAccOfcode="getIsAccOfcode" @isMobileOk="gotoLogin"></codeLogin>
        <div
          class="content"
          v-show="!smsShow && !dialogFormVisible && isAccOfcode"
          @keydown.enter.prevent="login()"
        >
          <div class="loginForm">
            <el-form
              :ref="'loginForm'"
              :model="formInline"
              :rules="rules"
              label-width="80px"
              label-position="top"
            >
              <el-form-item label="" :class="{ 'is-error': userErr }" prop="account">
                <el-input
                  autocomplete="off"
                  v-model.trim="computedAccount"
                  @change="
                    userErr = false;
                    userChange();
                  "
                  :placeholder="$t('placeholder.enter_one_user_name')"
                >
                  <i slot="prefix" class="el-input__icon el-icon-user"></i>
                </el-input>
              </el-form-item>
              <el-form-item label="" :class="{ 'is-error': userErr }" prop="password">
                <el-input
                  autocomplete="off"
                  v-model.trim="formInline.password"
                  :type="password"
                  :placeholder="$t('placeholder.Please_input_a_password')"
                  @change="userErr = false"
                >
                  <i slot="prefix" class="el-input__icon el-icon-lock"></i>
                </el-input>
                <img
                  :src="eyesN"
                  width="21"
                  height="13"
                  :class="['eyesY', { eyes_password: password == 'password' }]"
                  @click="eyesYClick"
                />
              </el-form-item>
              <el-form-item
                v-show="verifyCodeState"
                :class="{ 'is-error': codeErr }"
                prop="verifyCode"
              >
                <el-input
                  v-model.trim="formInline.verifyCode"
                  :placeholder="$t('placeholder.Please_enter_the_verification_code')"
                  @change="codeErr = false"
                  class="ui_verification srtelnum"
                  style="width: 192px"
                ></el-input>
                <div class="inline-block checkCode" @click="changeCode">
                  <img :src="codeImg" width="100%" height="100%" />
                </div>
              </el-form-item>
              <div>
                <el-checkbox v-model="remPsd" class="jz-psd">{{
                  $t("login_page.automatic_logon")
                }}</el-checkbox>
                <span class="find-psd"
                  ><router-link :to="{ path: '/findPsd' }">{{
                    $t("login_page.Forgot_password")
                  }}</router-link></span
                >
              </div>
            </el-form>
            <!-- <span class="errorMsg" v-show="showMsg">{{errorMsg}}</span> -->
            <el-button
              type="primary"
              :loading="loading"
              @click="login()"
              style="width: 100%; margin-top: 24px; margin-bottom: 40px"
              >{{ $t("button.signin") }}</el-button
            >
          </div>
        </div>
        <div
          class="content"
          v-show="smsShow && !dialogFormVisible && isAccOfcode"
          @keydown.enter.prevent="verifySms"
        >
          <!--          <div class="title" v-show="false">-->
          <!--            <div class="letf-items" style="float: left">用户登录</div>-->
          <!--            <div class="right-items" style="float: right" @click="logout">-->
          <!--              {{ backText }}-->
          <!--            </div>-->
          <!--          </div>-->
          <div class="loginForm">
            <el-form :ref="'smsForm'" :model="formInlineSms" :rules="rulesSms" label-width="70px">
              <i class="el-icon-warning-outline" style="margin-bottom: 30px">{{
                loginStateText
              }}</i>
              <el-form-item :label="$t('placeholder.cell_phone_number')" v-if="!needReset">
                <span style="line-height: 40px; color: #606266">{{ maskMobile }}</span>
              </el-form-item>
              <el-form-item
                :label="$t('placeholder.cell_phone_number')"
                prop="mobileNew"
                v-if="needReset"
              >
                <!-- <el-input style="position: fixed; bottom: -9999px" /> -->
                <el-input
                  v-model.trim="formInlineSms.mobileNew"
                  :placeholder="$t('placeholder.Please_enter_your_phone_number')"
                  :maxlength="11"
                  :disabled="formInlineSms.lockMobile"
                  class="ui_verification srtelnum resetInput firstInput"
                ></el-input>
                <!-- <el-button
                  :loading="loading"
                  @click="beforeGetSmsVeifyCode()"
                  style="margin-left: 10px; width: 120px"
                  class="srtelnumbtn"
                  :disabled="sendCk"
                  >{{ showT }}</el-button> -->
              </el-form-item>
              <el-form-item
                :label="$t('placeholder.check_sum')"
                :class="{ 'is-error': codeErr }"
                prop="graphVeifyCode"
                v-if="smsShow"
                class="sryzmVcode code-box"
              >
                <el-input
                  maxlength="6"
                  class="ui_verification resetInput"
                  v-model.trim="formInlineSms.graphVeifyCode"
                  :placeholder="$t('placeholder.Please_enter_the_check_sum')"
                ></el-input>
                <img :src="codeImg" class="code-img" @click="changeResetCode" />
              </el-form-item>
              <el-form-item
                :label="$t('placeholder.Verification_code')"
                :class="{ 'is-error': codeErr }"
                prop="verifyCode"
                v-if="smsShow"
                class="sryzmVcode"
              >
                <el-input
                  maxlength="6"
                  class="ui_verification resetInput"
                  v-model.trim="formInlineSms.verifyCode"
                  :placeholder="$t('placeholder.Please_enter_the_verification_code')"
                ></el-input>
                <el-button
                  :loading="loading"
                  @click="beforeGetSmsVeifyCode()"
                  style="margin-left: 18px; width: 78px; padding: 12px 0"
                  class="yzmVcode"
                  :disabled="sendCk"
                >
                  {{ showT }}
                </el-button>
              </el-form-item>
            </el-form>
            <el-button
              type="primary"
              :loading="loading"
              @click="verifySms()"
              class="yzmVscodeBtn"
              style="width: 100%; margin-top: 24px; margin-bottom: 40px"
              >{{ $t("button.signin") }}</el-button
            >
          </div>
        </div>
        <div
          class="content firstLogin"
          v-show="dialogFormVisible"
          @keydown.enter.prevent="modifyPwd"
        >
          <!-- <div class="title">
            <div class="right-items" style="float: right" @click="logout">
              {{ backText }}
            </div>
          </div> -->
          <div class="loginForm">
            <el-form
              class="oneXgmm"
              :model="form"
              label-position="right"
              :rules="rulesModifyPwd"
              ref="ruleForm"
            >
              <i class="el-icon-warning-outline" style="margin: 0 0 20px !important">
                <span>{{ $t("login_page.First_login_change_your_login_password") }}</span></i
              >
              <!-- <el-form-item
                label="请输入原密码"
                label-width="140px"
                prop="password"
              >
                <el-input
                  v-model.trim="form.password"
                  auto-complete="off"
                  type="password"
                ></el-input>
              </el-form-item> -->
              <el-form-item style="margin-bottom: 32px" prop="newPassword">
                <el-input
                  :placeholder="$t('placeholder.Please_enter_a_new_password')"
                  v-model.trim="form.newPassword"
                  auto-complete="off"
                  type="password"
                ></el-input>
              </el-form-item>
              <el-form-item prop="affirmNewPassword">
                <el-input
                  :placeholder="$t('placeholder.Please_enter_the_new_password_again')"
                  v-model.trim="form.affirmNewPassword"
                  auto-complete="off"
                  type="password"
                ></el-input>
              </el-form-item>
            </el-form>
            <div
              slot="footer"
              class="dialog-footer"
              style="text-align: center; margin-bottom: 40px"
            >
              <el-button type="primary" @click="modifyPwd">{{ $t("button.determine") }}</el-button>
              <el-button type="primary" @click="logout">{{ $t("button.cancel") }}</el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--    :append-to-body="true"-->
    <el-dialog
      width="380px"
      :append-to-body="true"
      :title="$t('placeholder.Please_enter_the_verification_code')"
      :close-on-click-modal="false"
      :before-close="handleClose"
      :visible.sync="graphVeifyCodeShow"
    >
      <el-form
        @submit.native.prevent="verifyGraphCode"
        :model="formInlineSms"
        label-position="right"
        style="margin-left: 20px; margin-right: 30px"
      >
        <el-form-item label="" class="graphcode">
          <el-input
            v-model.trim="formInlineSms.graphVeifyCode"
            placeholder="请输入右图中的验证码"
            @change="codeErr = false"
            style="width: 180px"
          ></el-input>
          <div class="inline-block" @click="changeResetCode" style="margin-left: 10px">
            <img :src="codeImg" width="150%" height="150%" />
          </div>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer" style="text-align: center">
        <el-button type="primary" @click="verifyGraphCode">{{ $t("button.determine") }}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { jsMd5, dateFormat, getQueryVariable } from "@/common/js/public.js";
import codeLogin from "./codeLogin";
import * as utils from "@/common/js/utils.js";
// sso
import _ from "lodash";
import router, { resetRouter } from "@/router";
import asyncRoutes from "@/router/asyncRoutes";
import { MessageBox } from "element-ui";
// import defaultRoutes from '@/router/defaultRoutes';
// import jsMd5 from 'js-md5'
// const TIME_COUNT = 60; // 倒计时的时间
const SAFE_LOGIN = true; // 是否安全登录，是则根据规则需要验证短信密码，修改密码等
export default {
  name: "login",
  components: {
    codeLogin,
  },
  data() {
    const checkUser = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t("placeholder.enter_one_user_name")));
      }
      callback();
    };
    const checkPwd = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t("placeholder.Please_input_a_password")));
      } else {
        callback();
      }
    };
    return {
      ssoLoading: false, // sso
      protalModule: decodeURIComponent(getQueryVariable("module")), // sso
      loginId: decodeURIComponent(getQueryVariable("loginId")), // sso
      eyesY: require("@/assets/img/eyesY.png"),
      eyesN: require("@/assets/img/eyesN.png"),
      password: "password",
      deptID: "",
      deptTree: [],
      isAccOfcode: true, // 默认账号--扫码登录
      timer: null,
      dialogFormVisible: false,
      // 2.2.5-UI改版
      loginBg: require("@/assets/img/protal_background.png"), // 登录页背景图
      loginTitleName: "智慧泊车管理平台", // 登录页标题文案
      loginTitleImage: require("@/assets/img/AIparkCity.png"), // 登录页标题LOGO
      form: {
        password: "",
        newPassword: "",
        affirmNewPassword: "",
      },
      rulesModifyPwd: {
        // password: [
        //   {
        //     required: true,
        //     message: "请输入原密码",
        //     trigger: "blur",
        //   },
        // ],
        newPassword: [
          {
            required: true,
            message: this.$t("placeholder.Please_enter_a_new_password"),
            trigger: "blur",
          },
          {
            validator: (rule, value, callback) => {
              var regex = new RegExp("(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])(?=.*[^a-zA-Z0-9]).{8,20}");
              if (!regex.test(value)) {
                callback(new Error("大小写字母+数字+特殊字符8-20位"));
              } else {
                callback();
              }
            },
            trigger: "blur",
          },
        ],
        affirmNewPassword: [
          {
            required: true,
            message: this.$t("placeholder.Please_enter_the_new_password_again"),
            trigger: "blur",
          },
          {
            validator: (rule, value, callback) => {
              if (value != this.form.newPassword) {
                callback(new Error("两次密码输入不一致"));
              } else {
                callback();
              }
            },
            trigger: ["blur", "blur"],
          },
        ],
      },
      formInline: {
        account: "",
        password: "",
        verifyCodeId: "",
        verifyCode: "",
      },
      formInlineSms: {
        mobile: "", // 更换ip，返回手机号
        smsVerifyCode: "",
        mobileNew: "", // 首次登录，需要修改手机号
        graphVeifyCode: "", // 首次登录，需要
        lockMobile: false,
      },
      backText: this.$t("button.return"),
      loginStateText: "",
      account: "",
      showT: this.$t("login_page.Obtain_verification_code"),
      sendCk: false,
      needSms: false, // 是否需要短信认证：true需要，false不需要; (接口：1需要)
      needReset: false, // 是否需要重新设置密码：1需要，2不需要
      // mobile: "", // 如果需要短信认证返回用户手机号
      smsShow: false,
      graphVeifyCodeShow: false,
      resetPhoneShow: false,
      verifyCodeState: false,
      remPsd: false,
      codeImg: "",
      checkTime: 0,
      errorMsg: "",
      codeErr: false,
      userErr: false,
      loading: false,
      rules: {
        account: [{ validator: checkUser, trigger: "blur" }],
        password: [{ validator: checkPwd, trigger: "blur" }],
      },
      rulesSms: {
        mobileNew: [
          {
            required: this.needReset,
            message: this.$t("placeholder.Please_enter_your_phone_number"),
            trigger: "change",
          },
          {
            validator: (rule, value, callback) => {
              if (!this.needReset) {
                callback();
              } else {
                if (!value) {
                  callback(new Error(this.$t("placeholder.Please_enter_your_phone_number")));
                } else if (!/^\d{11}$/.test(value)) {
                  callback(new Error(this.$t("error_message.phone_number_incorrect")));
                } else {
                  callback();
                }
              }
            },
            trigger: "blur",
          },
        ],
        verifyCode: [
          {
            required: true,
            message: this.$t("placeholder.Please_enter_the_verification_code"),
            trigger: "change",
          },
        ],
        graphVeifyCode: [
          {
            required: true,
            message: this.$t("placeholder.Please_enter_the_verification_code"),
            trigger: "change",
          },
        ],
      },
      sessionId: "",
    };
  },
  methods: {
    eyesYClick() {
      if (this.password == "password") {
        this.password = "text";
        this.eyesN = require("@/assets/img/eyesY.png");
      } else {
        this.password = "password";
        this.eyesN = require("@/assets/img/eyesN.png");
      }
    },
    beforeGetSmsVeifyCode() {
      if (!this.formInlineSms.mobileNew && !this.formInlineSms.mobile) {
        return this.$message.error(this.$t("placeholder.Please_enter_your_phone_number"));
      }

      if (!this.formInlineSms.graphVeifyCode) {
        return this.$message.error(this.$t("placeholder.Please_enter_the_verification_code"));
      }
      this.verifyGraphCode();
      // this.graphVeifyCodeShow = true;
      // this.formInlineSms.graphVeifyCode = "";
      // this.changeResetCode();
    },
    getSmsVeifyCode() {
      sessionStorage.token = this.sessionId;
      var mobile = this.needReset ? this.formInlineSms.mobileNew : this.formInlineSms.mobile;
      this.$axios
        .get("/acb/2.0/systems/login/createPasswordCheckCode", {
          data: {
            mobile: mobile,
            v: new Date().getTime(),
          },
        })
        .then((res) => {
          if (res.state == 0) {
            this.account = res.value;
            this.sendCk = true;
            this.formInlineSms.lockMobile = true;
            this.setSeconds();
          } else {
            this.$alert(res.desc, this.$t("pop_up.Tips"), {
              confirmButtonText: this.$t("button.determine"),
            });
          }
        });
    },
    setSeconds() {
      let seconds = 60;
      this.timer = setInterval(() => {
        this.showT = seconds-- + "s";
        if (seconds < 1) {
          this.showT = this.$t("login_page.Obtain_verification_code");
          this.sendCk = false;
          clearInterval(this.timer);
        }
      }, 1000);
    },
    userChange() {
      this.verifyCodeState = false;
      this.rules.verifyCode = [];
    },
    checkCode(rule, value, callback) {
      if (!value) {
        return callback(new Error(this.$t("placeholder.Please_enter_the_verification_code")));
      } else {
        callback();
      }
    },
    login() {
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          this.formInline.verifyCodeId = this.formInline.account;
          let { account, password, verifyCode, verifyCodeId } = this.formInline;
          let userInfo = { account, password, verifyCode, verifyCodeId };
          userInfo.password = jsMd5(account, jsMd5(account, userInfo.password));
          // 前后端约定版本号，每次升级手动更新，需前后端一致
          userInfo.version = "31d37cbfe418218343b7bd662706c471";
          sessionStorage.account = account;
          this.$axios
            .post("/acb/2.0/systems/login/login", {
              data: userInfo,
            })
            .then(
              (res) => {
                if (res.desc === "9999") {
                  window.location.href =
                    window.location.href + "?timestamp=" + new Date().getTime();
                  // this.$alert('登录失败，请从新登陆').then(() => {})
                  return;
                }
                this.needSms = res.value.extras.needSms == 1;
                this.needReset = res.value.extras.needReset == 1;
                this.formInlineSms.mobile =
                  res.value.extras && res.value.extras.mobile ? res.value.extras.mobile : "";
                if (this.needReset) {
                  this.loginStateText = this.$t("login_page.Detected_first_login");
                  this.backText = "<<返回";
                  this.changeCode();
                } else if (this.needSms) {
                  this.loginStateText = this.$t("login_page.The_IP_address_you_logged");
                  this.backText = "<返回";
                  this.changeCode();
                }
                if (res.state == 0 || res.state == "26") {
                  if (this.remPsd) {
                    this.formInline.verifyCode = "";
                    localStorage.setItem("userInfo", JSON.stringify(this.formInline));
                  } else {
                    localStorage.clear();
                  }
                  console.log("this.needSms----->", this.needSms);
                  sessionStorage.loginTime = dateFormat(new Date());
                  sessionStorage.downListTimeStamp = new Date().getTime();
                  sessionStorage.userName = res.value.userName;
                  this.$store.commit("SET_USERNAME", res.value.userName);
                  sessionStorage.userId = res.value.userId;
                  sessionStorage.admin = res.value.admin;
                  sessionStorage.toLogin = true;
                  if (!this.needSms) {
                    sessionStorage.token = res.value.sessionId;
                    this.getLogoName(); // 获取权限
                  } else {
                    this.sessionId = res.value.sessionId;
                    // sessionStorage.token = res.value.sessionId;
                    this.goPortal();
                    // this.getLogoName(); // 获取权限
                  }
                }
              },
              (res) => {
                this.changeCode();
                if (res.state == 37) {
                  this.verifyCodeState = true;
                  this.rules.verifyCode = [{ validator: this.checkCode, trigger: "blur" }];
                } else {
                  this.verifyCodeState = false;
                  this.rules.verifyCode = [];
                }
              }
            );
        } else {
          return false;
        }
      });
    },
    // 扫码登录
    gotoLogin(res) {
      this.needSms = res.value.extras.needSms == 1;
      this.needReset = res.value.extras.needReset == 1;
      this.formInlineSms.mobile =
        res.value.extras && res.value.extras.mobile ? res.value.extras.mobile : "";
      if (res.state == 0 || res.state == "26") {
        console.log("this.needSms----->", this.needSms);
        sessionStorage.loginTime = dateFormat(new Date());
        sessionStorage.downListTimeStamp = new Date().getTime();
        sessionStorage.userName = res.value.userName;
        this.$store.commit("SET_USERNAME", res.value.userName);
        sessionStorage.userId = res.value.userId;
        sessionStorage.admin = res.value.admin;
        sessionStorage.toLogin = true;
        if (!this.needSms) {
          sessionStorage.token = res.value.sessionId;
          this.getLogoName(); // 获取权限
        } else {
          this.sessionId = res.value.sessionId;
          this.goPortal();
          // this.getLogoName(); // 获取权限
        }
      }
    },
    verifySms() {
      this.$refs.smsForm.validate((valid) => {
        if (valid) {
          // this.dialogFormVisible = true;
          var mobile = this.needReset ? this.formInlineSms.mobileNew : this.formInlineSms.mobile;
          this.$axios
            .post("/acb/2.0/systems/login/verifySmsCode", {
              data: {
                mobile: mobile,
                smsCode: this.formInlineSms.verifyCode,
              },
            })
            .then(
              (res) => {
                if (this.needReset) {
                  this.resetMobile();
                  // this.dialogFormVisible = true;
                } else {
                  this.loginSuccess();
                }
              },
              (res) => {
                // error
              }
            );
        }
      });
    },
    modifyPwd() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          let d = { ...this.form };
          d.password = this.formInline.password;
          d.password = jsMd5(sessionStorage.account, jsMd5(sessionStorage.account, d.password));
          d.newPassword = jsMd5(sessionStorage.account, d.newPassword);
          let opt = {
            method: "post",
            url: "/acb/2.0/systems/login/modifyPassword",
            data: d,
            success: (res) => {
              if (res.state == 0) {
                // this.dialogFormVisible = false;
                this.loginSuccess();
              } else {
                this.$alert(res.desc, this.$t("pop_up.Tips"), {
                  confirmButtonText: this.$t("button.determine"),
                });
              }
            },
          };
          this.$request(opt);
        } else {
          return false;
        }
      });
    },
    verifyGraphCode() {
      this.$axios
        .post("/acb/2.0/systems/login/verifyCode", {
          data: {
            verifyCodeId: this.formInline.account,
            verifyCode: this.formInlineSms.graphVeifyCode,
          },
        })
        .then(
          (res) => {
            this.graphVeifyCodeShow = false;
            this.getSmsVeifyCode();
          },
          (res) => {
            this.changeResetCode();
          }
        );
    },
    resetMobile() {
      this.$axios
        .post("/acb/2.0/systems/login/resetMobile", {
          data: {
            mobile: this.formInlineSms.mobileNew,
          },
        })
        .then(
          (res) => {
            this.dialogFormVisible = true;
          },
          (res) => {}
        );
    },
    loginSuccess() {
      if (this.needSms) {
        sessionStorage.token = this.sessionId;
      }
      this.formInlineSms.lockMobile = false;
      this.$axios.post("/acb/2.0/systems/login/addLog").then(
        (res) => {},
        (res) => {}
      );
      sessionStorage.notificationShowing = 0;
      sessionStorage.blockNotification = 0;

      // sso
      if (this.protalModule === "") {
        this.$router.push("/portal");
      } else {
        this.jumpToPage();
      }
    },
    handleClose(done) {
      this.logout(done);
    },
    logout(done) {
      this.$confirm("是否退出?", this.$t('pop_up.Tips'), {
        confirmButtonText: this.$t('pop_up.Determine'),
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let opt = {
            url: "/acb/2.0/systems/login/logout",
            method: "post",
            data: {
              sessionId: sessionStorage.token,
            },
            success: (res) => {
              this.$message({
                type: "success",
                message: "退出成功!",
              });
              sessionStorage.clear();
              this.smsShow = false;
              this.dialogFormVisible = false;
              this.formInlineSms.mobile = "";
              this.formInlineSms.smsVerifyCode = "";
              this.formInlineSms.mobileNew = "";
              this.formInlineSms.verifyCode = "";
              this.formInlineSms.graphVeifyCode = "";
              this.formInlineSms.lockMobile = false;
              this.form.password = "";
              this.form.newPassword = "";
              this.form.password = "";
              this.form.affirmNewPassword = "";
              this.$refs.smsForm.resetFields();
              this.$refs.ruleForm.resetFields();
              done();
            },
          };
          this.$request(opt);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消退出",
          });
        });
    },
    changeCode() {
      this.$axios
        .get("/acb/2.0/systems/login/verificationCode", {
          data: {
            verifyCodeId: this.formInline.account,
            v: new Date().getTime(),
          },
        })
        .then((res) => {
          this.codeImg = "data:image/gif;base64," + res.value;
        });
    },
    changeResetCode() {
      this.$axios
        .get("/acb/2.0/systems/login/verificationCode", {
          data: {
            verifyCodeId: this.formInline.account,
            v: new Date().getTime(),
          },
        })
        .then((res) => {
          this.codeImg = "data:image/gif;base64," + res.value;
        });
    },
    clearBus() {
      this.$EventBus.$off("addTab");
    },
    getIsAccOfcode(isAccOfcode) {
      this.isAccOfcode = isAccOfcode;
    },
    // 按部门查询logo+平台名称
    getLogoName() {
      if (this.needSms) {
        sessionStorage.token = this.sessionId;
      }
      this.$axios.get("/acb/2.0/dept/getDeptTree").then((res) => {
        this.deptTree = [res.value];
        this.$axios
          .get("/acb/2.0/bossManager/list", {
            data: {
              page: 1,
              size: 15,
              account: sessionStorage.account ? sessionStorage.account : "",
              mobile: this.formInlineSms.mobile ? this.formInlineSms.mobile : "",
            },
          })
          .then((res) => {
            this.deptID = res.value.list[0].deptId;
            // 存储自己部门
            sessionStorage.selfDeptId = this.deptID;
            // 判断部门归属二级一级
            this.returnLevel(this.deptID, this.deptTree);
          });
      });
    },
    // 递归取一、二级用户
    returnLevel(deptId, arr) {
      arr.forEach((ele1) => {
        if (ele1.deptId == deptId && ele1.level == 1) {
          this.returnLogoName(this.deptID);
        } else if (ele1.deptId == deptId && ele1.level == 2) {
          this.returnLogoName(this.deptID);
        } else if (ele1.deptId == deptId && ele1.level > 2) {
          this.returnLevel22(ele1.parentId, this.deptTree);
        } else if (ele1.childNodes) {
          this.returnLevel(deptId, ele1.childNodes);
        }
      });
    },
    // 递归通过三、四、、、取对应二级
    returnLevel22(parentId, arr) {
      arr.forEach((ele1) => {
        if (ele1.deptId == parentId && ele1.level == 2) {
          this.returnLogoName(ele1.deptId);
        } else if (ele1.deptId == parentId && ele1.level > 2) {
          this.returnLevel22(ele1.parentId, this.deptTree);
        } else if (ele1.childNodes) {
          this.returnLevel22(parentId, ele1.childNodes);
        }
      });
    },
    returnLogoName(deptId) {
      this.$axios
        .post("/acb/2.0/deptProject/detail", {
          data: {
            deptId: deptId,
          },
        })
        .then((res) => {
          if (res.state == 0 && res.value) {
            console.log(res.value);
            sessionStorage.platformName = res.value.platformName;
            if (res.value.logoUrl) {
              sessionStorage.logoUrl = res.value.logoUrl;
            }
            if (res.value.backgroundUrl) {
              sessionStorage.backgroundUrl = res.value.backgroundUrl;
            }
            if (res.value.footer) {
              sessionStorage.footer = res.value.footer;
            }
          }
          if (!this.smsShow) {
            this.goPortal();
          }
        });
    },
    goPortal() {
      if (SAFE_LOGIN) {
        if ((this.needSms || this.needReset) && sessionStorage.account) {
          // show Sms veifiy code
          this.smsShow = true;
          this.getLogoName();
        } else {
          this.loginSuccess();
        }
      } else {
        sessionStorage.notificationShowing = 0;
        sessionStorage.blockNotification = 0;
      }
    },
    // sso
    async getResource() {
      if (this.$store.state.menu.allAuthorityMenu.length === 0) {
        // 通过vuex 获取权限
        let allAuRoutes = await this.$store.dispatch("menu/GET_MENU");
        let _allAuRoutes = utils.flattenDeep(allAuRoutes);
        let _asyncRoutes = _.cloneDeep(asyncRoutes);
        let mockAsync = [];
        // 过滤出 有权限的 菜单
        _asyncRoutes[0].children = _allAuRoutes.reduce((mock, route) => {
          // console.log('route--->', route);
          _asyncRoutes[0].children.forEach((v) => {
            // 根据name 判断为哪个页面的子页面 可能会有多个根路由 pathAuInclude 会根据name 找个多个根路由 目前只支持 同一个根路由下的 多个子页面的name 如果一个页面 有多个根路由 会有问题 建议复制页面 给子页面配置不同的路由
            if (
              utils.pathAuInclude(route.webUri, v.name) &&
              !utils.findArrEle(mockAsync, "name", v.name)
            ) {
              // if (v.meta) {
              //   v.meta['portalKey'] = route.portal
              // }
              // if (!v.meta) {
              //   Object.assign(v, { 'meta': { 'portalKey': route.portal } })
              // }
              // findArrEle 确定路由没有重复添加
              mock.push(v);
            }
          });
          return mock;
        }, mockAsync);
        resetRouter(); // 重置路由
        // router.addRoutes(defaultRoutes);
        router.addRoutes(_asyncRoutes);
      }
    },
    ssologin() {
      this.$axios
        .post(
          "/acb/2.0/systems/login/sso/pwdByPass",
          {},
          {
            headers: { loginId: this.loginId },
          }
        )
        .then(
          (res) => {
            if (res.desc === "9999") {
              window.location.href = window.location.href + "?timestamp=" + new Date().getTime();
              // this.$alert('登录失败，请从新登陆').then(() => {})
              return;
            }
            this.needSms = res.value.extras.needSms == 1;
            this.needReset = res.value.extras.needReset == 1;
            this.formInlineSms.mobile =
              res.value.extras && res.value.extras.mobile ? res.value.extras.mobile : "";
            if (this.needReset) {
              this.loginStateText = " 检测到首次登录，请绑定您的手机";
              this.backText = "<<返回";
              this.changeCode();
            } else if (this.needSms) {
              this.loginStateText = " 您本次登录的ip地址发生变化，请输入短信验证码后登录";
              this.backText = "<返回";
              this.changeCode();
            }
            if (res.state == 0 || res.state == "26") {
              if (this.remPsd) {
                this.formInline.verifyCode = "";
                localStorage.setItem("userInfo", JSON.stringify(this.formInline));
              } else {
                localStorage.clear();
              }
              console.log("this.needSms----->", this.needSms);
              sessionStorage.loginTime = dateFormat(new Date());
              sessionStorage.downListTimeStamp = new Date().getTime();
              sessionStorage.userName = res.value.userName;
              this.$store.commit("SET_USERNAME", res.value.userName);
              sessionStorage.userId = res.value.userId;
              sessionStorage.admin = res.value.admin;
              sessionStorage.toLogin = true;
              if (!this.needSms) {
                sessionStorage.token = res.value.sessionId;
                this.getLogoName(); // 获取权限
              } else {
                this.sessionId = res.value.sessionId;
                // sessionStorage.token = res.value.sessionId;
                this.goPortal();
                // this.getLogoName(); // 获取权限
              }
            }
          },
          (res) => {
            MessageBox.alert(res.desc, this.$t("pop_up.Tips"), {
              confirmButtonText: this.$t("button.determine"),
              callback: (action) => {
                const origin = window.location.origin;
                window.location.replace(`${origin}/`);
              },
            });
          }
        );
    },
    async jumpToPage() {
      let protalKey = "";
      let path = "";
      await this.getResource();
      if (this.protalModule.includes("/portal")) {
        this.$router.push("/portal");
        return;
      } else if (
        this.protalModule == "http://acc-pre.acs.aipark.com/autoLogin" ||
        this.protalModule == "http://acc-pre.ace.aipark.com/autoLogin"
      ) {
        protalKey == "CHARGING_BUSINESS";
      } else {
        const str = this.protalModule.split("://");
        const index = str[1].indexOf("/") + 1;
        path = str[1].substring(index);
        protalKey = this.findKeyByCode(this.$store.state.menu.allAuthorityMenu, path);
      }
      // let path = this.protalModule.toUpperCase();
      let au = this.$store.state.menu.authorityMenu;
      this.$store.dispatch("menu/CHANGE_MENU", []);
      this.$store.dispatch("tabs/REMOVE_TABS");
      let ifLink = !!au[protalKey];
      if (!ifLink) {
        this.$message.closeAll();
        MessageBox.alert("您没有权限！", this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine'),
          callback: (action) => {
            const origin = window.location.origin;
            window.location.replace(`${origin}/`);
          },
        });
        return;
      }
      switch (true) {
        case protalKey == "CHARGING_BUSINESS":
          window.open(this.getUrl());
          break;
        default: {
          const portalMenu = utils.findPortalMenu(this.$store.state.menu.authorityMenu, protalKey);
          this.$store.dispatch("menu/CHANGE_MENU", portalMenu);
          this.$router.replace(path);
          // window.location.replace(this.protalModule);
        }
      }
    },
    findKeyByCode(value, code) {
      for (const key in value) {
        // eslint-disable-next-line no-prototype-builtins
        if (value.hasOwnProperty(key)) {
          const items = value[key];
          const foundItem = items.find((item) => item.code === code);
          if (foundItem) {
            return key;
          } else {
            for (const item of items) {
              if (item.children) {
                const childKey = this.findKeyByCode({ temp: item.children }, code);
                if (childKey) {
                  return key;
                }
              }
            }
          }
        }
      }
      return null; // 如果未找到匹配的key值，返回null或其他自定义值
    },
    getUrl() {
      let url = "";
      if (process.env.VUE_APP_BUILD_MODE === "pre") {
        url = `http://acc-pre.acs.aipark.com/autoLogin?userId=${sessionStorage.userId}&t=${
          sessionStorage.token
        }&u=${sessionStorage.userName}&timestamp=${new Date().getTime()}`;
      } else {
        url = `http://acc-pre.ace.aipark.com/autoLogin?userId=${sessionStorage.userId}&t=${
          sessionStorage.token
        }&u=${sessionStorage.userName}&timestamp=${new Date().getTime()}`;
      }
      return url;
    },
    findFirstMenu(menu) {
      if (menu.children && menu.children.length) {
        return this.findFirstMenu(menu.children[0]);
      }
      return menu;
    },
    // 动态赋值浏览器顶部图标
    setUPIcon(url) {
      let link = document.querySelector("link[rel*='icon']") || document.createElement("link");
      link.type = "image/x-icon";
      link.rel = "shortcut icon";
      link.href = url; // 图片放public目录
      document.getElementsByTagName("head")[0].appendChild(link);
    },
    setUpTitle(title) {
      document.getElementsByTagName("title")[0].innerText = title;
    },
  },
  computed: {
    maskMobile() {
      return (
        this.formInlineSms.mobile.substring(0, 3) + "****" + this.formInlineSms.mobile.substring(7)
      );
      // return this.formInlineSms.mobile.replace(/(\d{3})\d*(\d{4})/, '$1****$2');
    },
    computedAccount: {
      get: function () {
        return this.formInline.account;
      },
      set: function (val) {
        this.formInline.account = val.toLowerCase();
      },
    },
  },
  created() {
    // removeSession();
    this.$store.dispatch("menu/CLEAR_MENU");
    sessionStorage.clear();
    if (this.protalModule !== "") {
      this.ssoLoading = true;
      this.ssologin();
    }
  },
  mounted() {
    let userInfo = localStorage.getItem("userInfo");
    if (userInfo) {
      userInfo = JSON.parse(userInfo);
      // this.formInline = userInfo;
      this.formInline.account = userInfo.account;
      this.formInline.password = userInfo.password;
      this.remPsd = true;
    }
    this.$axios.get("/acb/2.0/deptProject/getLoginImage").then((res) => {
      if (res.value.loginTitle) {
        this.loginTitleName = res.value.loginTitle;
        this.setUpTitle(this.loginTitleName);
        sessionStorage.setItem("loginTitleName", this.loginTitleName);
      }
      if (res.value.loginLogoUrl) {
        this.loginTitleImage = res.value.loginLogoUrl;
        this.setUPIcon(this.loginTitleImage);
        sessionStorage.setItem("loginTitleImage", this.loginTitleImage);
      }
      if (res.value.loginBackgroundUrl) {
        this.loginBg = res.value.loginBackgroundUrl;
      }
    });
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus" rel="stylesheet/stylus">
.eyesY{
   position: absolute;
   right: 16px;
   top: 14px;
   &.eyes_password{
     width 20px
     height 10px
     top 18px
   }
}
>>>.el-button--primary{
  background-color: #394DFF;
  border-color: #394DFF;
  border-radius:2px
}
.loginBg{
  position absolute
  top 0
  left 0
  width 100%
  height 100%
  min-width 1366px
}
.find-psd {
  position absolute
  right 0
  a {
    text-decoration: none;
    font-size 14px
    color #527BFF
    height: 19px;
    line-height: 19px;
  }
}
.jz-psd {
  font-size 16px
  color #D0D0D0
}

.graphcode {
  >>>.el-form-item__content {
    display: flex;
    flex-direction: row;
    align-items: center;
    line-height: 0;
  }
}
.wrapper{
  padding-top:36px;
}
.login {
  background: url('../../assets/img/protal_background.png') no-repeat center / cover;
  height: 100%;
  width: 100%;
  position relative
  .code-box {
    position: relative;
  }
  .code-img  {
    position: absolute;
    right: 4px;
    top: 50%;
    transform: translateY(-50%);
    width: 78px;
    height:36px;
    cursor: pointer;
  }
  .middle {
    width 366px;
    min-height: 500px;
    right 8%
    padding 45px 45px
    position: absolute;
    top 50%
    right 13%
    transform: translateY(-50%);
    background #fff
    border-radius: 8px;
    box-sizing border-box

    .header {
      display:flex;
      justify-content: flex-start
      align-items: center;
      padding-left:22px;
      p {
        line-height:30px;
        height: 30px;
        font-size: 21px;
        font-family: "PingFang";
        color:#01224A;
      }
      img {
        vertical-align: middle;
        width: 50px;
        height:30px;
        margin-right:9px;
      }
    }
    .content {
      width: 276px;
      .el-icon-warning-outline{
        span{
          line-height: 1.4;
        }
      }
      .title {
        height: 60px;
        line-height: 60px;
        padding-left: 30px;
        padding-right: 30px;
        font-family: MicrosoftYaHei-Bold;
        font-size: 14px;
        color: #FFFFFF;
        letter-spacing: 0;
      }
      .loginForm {
        top 20px !important;
        position: relative;
        .ui_verification >>> .el-input__inner{
          width: 100%;
          padding: 0 10px;
        }
        .ui_verification.resetInput.el-input {
          width: 51%;
        }
        .ui_verification.resetInput.firstInput.el-input {
          width: 100%;
        }
        .ui_verification.resetInput >>> .el-input__inner {
          height: 40px;
        }
        >>>.el-input__inner {
          width 276px
          height 36px
          font-size 14px
          padding 0 40px
        }
        /deep/ .el-button {
          padding: 11px 16px;
        }
        .oneXgmm >>>.el-input__inner {
          height 36px
          font-size 14px
          padding 0 40px
        }
        >>>.el-input__icon{
          font-size 22px
          margin-left 8px
        }
        >>>.el-checkbox__input.is-checked+.el-checkbox__label{
          color: gray;
        }
        .checkCode {
          width: 78px;
          float: right;
          text-align: center;
          height: 36px;
          overflow: hidden;
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
        }
        >>>.el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner{
          background #527BFF
          border-color #527BFF
        }

        .errorMsg {
          position: absolute;
          font-size: 12px;
          color: #FF4949;
          bottom: 77px;
        }
        >>>.el-input__icon:after{
          content: '';
          height: 109%;
          width: 0;
          display: inline-block;
          vertical-align: middle;
        }
      }
    }
    .firstLogin {
      .title {
        height: 40px;
        line-height: 40px;
        font-family: MicrosoftYaHei-Bold;
        font-size: 14px;
        color: #333;
        letter-spacing: 0;
      }
      .loginForm {
        top 0
      }
      .oneXgmm >>>.el-input__inner {
        height 40px!important
        padding 0 10px!important
      }
    }
  }
  /deep/ .el-form-item__label {
    line-height: 16px !important;
    padding: 11px 12px 11px 0;
    min-width: auto;
  }
}
</style>
