<template>
  <div>
    <el-row class="acc-code-login">
      <el-col :span="12" @click.native="isAccOfcode = true">
        <span :class="isAccOfcode ? 'acc-code-h3' : 'acc-code-h4'">{{
          $t("login_page.Account_login")
        }}</span>
        <span :class="isAccOfcode ? 'heng' : ''"></span>
      </el-col>
      <el-col :span="12" @click.native="isAccOfcode = false">
        <span :class="isAccOfcode ? 'acc-code-h4' : 'acc-code-h3'">{{
          $t("login_page.Scan_code_to_log_in")
        }}</span>
        <span :class="isAccOfcode ? '' : 'heng'"></span>
      </el-col>
      <!-- <el-col
        :span="12"
        style="text-align: right; padding-right: 40px; cursor: pointer"
        @click.native="isAccOfcode = !isAccOfcode"
      >
        <span class="acc-code-title">{{ !isAccOfcode ? "账号登录" : "扫码登录" }}</span>
        <span
          class="acc-code-img"
          :style="{
            backgroundImage:
              'url(' +
              require(isAccOfcode
                ? '../../assets/img/login/smdl.png'
                : '../../assets/img/login/zhdl.png') +
              ')',
          }"
        ></span>
      </el-col> -->
    </el-row>
    <div v-if="!isAccOfcode" class="code-login-img">
      <!-- 扫码登录页面 -->
      <div class="code-img" v-if="!isLoginSuccess">
        <div v-if="!loginCodeImgSendCk" class="code-invalid">
          <p>{{ $t("login_page.QR_code_invalid") }}</p>
          <el-button type="primary" @click="getLoginCode">{{
            $t("login_page.Refresh_QR_code")
          }}</el-button>
        </div>
        <vue-qr
          v-if="loginCodeImg"
          :text="loginCodeImg"
          :logoSrc="require('../../assets/img/AIpark28.png')"
          :size="150"
          :margin="0"
        ></vue-qr>
      </div>
      <!-- 成功登录页面 -->
      <div v-if="isLoginSuccess">
        <img src="../../assets/img/login/succee.png" width="32" height="32" />
        <p style="margin-top: 20px">{{ $t("login_page.Scan_code_successfully") }}</p>
        <p style="line-height: 50px">{{ $t("login_page.confirm_login_on_your_phone") }}</p>
        <vue-qr
          v-if="loginCodeImg"
          :text="loginCodeImg"
          :logoSrc="require('../../assets/img/AIpark28.png')"
          :size="100"
          :margin="0"
        ></vue-qr>
      </div>
      <p
        class="code-login-xiazai"
        v-if="!isLoginSuccess"
        @click="$refs.appDownload.showDialogVisible()"
      >
        {{ $t("login_page.open") }}<span>{{ $t("login_page.aipark_management_App") }}</span
        >{{ $t("login_page.Scan_code_to_log_in") }}
      </p>
    </div>
    <!-- app下载弹框 -->
    <!-- <el-dialog
          :visible.sync="dialogVisible"
          width="390px" style="text-align:center;">
          <p style="margin-top:20px;">扫码下载</p>
          <p style="line-height:50px;">智慧停车管理端APP</p>
          <vue-qr style="margin:38px 0;" :text="downloadData" :logoSrc="require('../../assets/img/AIpark28.png')" :size="234" :margin="0"></vue-qr>
        </el-dialog> -->
    <app-download ref="appDownload" />
  </div>
</template>

<script>
import VueQr from "vue-qr";
import appDownload from "./components/appDownload";
export default {
  name: "login",
  components: {
    VueQr,
    appDownload,
  },
  data() {
    return {
      isAccOfcode: true, // 默认账号--扫码登录
      loginCodeImg: "", // 登录二维码
      loginCodeImgSendCk: true, // 二维码是否失效
      loginCodeTimer: null, // 登录失效定时器
      isLoginSuccess: false, // 判断登录成功
      dialogVisible: false,
      logintimer: null, // 移动端确认轮询
      downloadData: window.location.protocol + "//" + window.location.host + "/downloadboss",
    };
  },
  methods: {
    // 获取登录二维码接口
    getLoginCode() {
      this.$axios.get("/acb/2.0/systems/login/getMobileLoginCode").then((res) => {
        this.loginCodeImg = "";
        this.loginCodeImg = res.value;
        let seconds = 60;
        this.loginCodeImgSendCk = true;
        this.loginCodeTimer = setInterval(() => {
          seconds--;
          if (seconds == -1) {
            this.loginCodeImgSendCk = false;
            clearInterval(this.loginCodeTimer);
          }
        }, 1000);
      });
    },
    getMobileOk() {
      this.$axios
        .get("/acb/2.0/systems/login/barCodeLogin", {
          data: {
            id: this.loginCodeImg.split("=")[1],
          },
        })
        .then((res) => {
          if (res.state == 0) {
            this.isLoginSuccess = true;
            clearInterval(this.logintimer);
            this.$emit("isMobileOk", res);
          }
        });
    },
  },
  watch: {
    isAccOfcode(val, oldVal) {
      if (!this.isAccOfcode) {
        console.log("isAccOfcode: ", this.isAccOfcode);
        this.getLoginCode();
      } else {
        this.loginCodeImgSendCk = true;
        clearInterval(this.loginCodeTimer);
        clearInterval(this.logintimer);
      }
      this.$emit("isAccOfcode", this.isAccOfcode);
    },
    loginCodeImg(val, oldVal) {
      clearInterval(this.logintimer);
      this.getMobileOk();
      let seconds = 60;
      this.logintimer = setInterval(() => {
        this.getMobileOk();
        seconds = seconds - 2;
        if (seconds == -2) {
          clearInterval(this.logintimer);
        }
      }, 2000);
    },
  },
  created() {},
  mounted() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.login .el-form-item__label {
  line-height: 16px !important;
  padding: 11px 12px 11px 0;
}

.acc-code-login {
  width: 188px;
  position: relative;
  margin: 0 auto;
  height: 18px;
  font-size: 15px;
  font-family: "PingFang";
  font-weight: 500;
  color: #151a2b;
  line-height: 18px;
  text-align: center;
}
.acc-code-h3 {
  height: 18px;
  font-size: 15px;
  font-family: "PingFang";
  font-weight: 500;
  color: #151a2b;
  line-height: 18px;
  cursor: pointer;
}
.acc-code-h4 {
  height: 18px;
  font-size: 15px;
  font-family: "PingFang";
  font-weight: 500;
  color: rgba(21, 26, 43, 0.4);
  line-height: 18px;
  cursor: pointer;
}
.acc-code-title {
  display: inline-block;
  width: 73px;
  height: 28px;
  background: #f5f5f5;
  line-height: 28px;
  text-align: center;
  border-radius: 4px;
  position: relative;
  font-size: 12px;
}
.acc-code-title::after {
  position: absolute;
  top: 9px;
  right: -10px;
  content: "";
  width: 0;
  height: 0;
  border: 5px solid transparent;
  border-left-color: #f5f5f5;
}
.acc-code-img {
  display: inline-block;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: 30px;
  width: 30px;
  position: absolute;
  top: -3px;
  right: 0px;
}
.code-login-img {
  padding: 30px 0;
  text-align: center;
}
.code-login-xiazai {
  cursor: pointer;
  margin-top: 20px;
  color: #999;
}
.code-login-xiazai span {
  color: #527bff;
}
.code-login-xiazai span:hover {
  color: #154dff;
}
.code-img {
  width: 150px;
  height: 150px;
  margin: 0 auto;
}
.code-invalid {
  position: absolute;
  text-align: center;
  width: 150px;
  height: 150px;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 100;
}
.code-invalid p {
  color: #fff;
  line-height: 30px;
  margin-top: 40px;
}
.heng {
  display: block;
  width: 18px;
  height: 3px;
  background: #394dff;
  border-radius: 15px 15px 15px 15px;
  opacity: 1;
  margin: 3px auto 0;
}
</style>
